import { Location } from "@/interfaces/location";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import startCase from "lodash/startCase";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  filters: {
    startCase,
    date: (val: string) => {
      return val
        ? fnsFormatDate(new Date(val), FNS_DATE_FORMATS.BARS_FULL_DATE)
        : "";
    }
  }
})
export class MoneyPrintMixin extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public location!: Location;
  @Prop({ required: true }) public data!: any;
}
