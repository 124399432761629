import { moneyEventTypes } from "@/enums/moneyManager";
import { Till } from "@/interfaces/money";
import { EventBus } from "@/internal";
import { moneyService } from "@/services/money.service";
import { Callback, PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PayoutSlipTillComponent } from "../manager/open-till/print/payout/PayoutSlipTill.component";
import Template from "./payoutFromTill.template.vue";

@Component({
  mixins: [Template]
})
export default class PayoutFromTillComponent extends Vue {
  @Getter("currentTill", { namespace: "UserModule" })
  public currentTill!: Till;
  @Action("setCurrentTill", { namespace: "UserModule" })
  public setCurrentTill!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public isFormValid = false;
  public loading = false;
  public model = {
    amount: "",
    descriprion: "",
    sub_type: moneyEventTypes.PayoutTill,
    from_employee: true
  };
  public titleActions: any = [];

  public async save() {
    // @ts-ignore
    this.$refs.payoutForm.validate();
    if (this.isFormValid) {
      this.loading = true;
      const response = await moneyService.payout(
        this.currentTill.assigned_user_id,
        this.currentTill.id,
        this.model,
        "till"
      );
      if (response) {
        await this.setCurrentTill();
        EventBus.$emit("print", {
          component: PayoutSlipTillComponent,
          props: {
            data: { user: this.currentTill.assigned_user, ...response }
          }
        });
        this.$router.push("/dashboard");
      }
      this.loading = false;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public validAmountRule(value: number) {
    const min = 1;
    if (+value < min) {
      return this.$t("money_manager.min_amount_error", { amount: min });
    }
    if (this.currentTill) {
      return (
        value < this.currentTill.cash || this.$t("available_amount_exceeded")
      );
    }

    return true;
  }

  protected async mounted() {
    if (this.currentTill) {
      this.setPageNav({
        title: "payout_from_till",
        isLoading: () => this.loading,
        rightActions: {
          generalActions: () => [
            {
              icon: "fal fa-check",
              action: this.save,
              vuetifyProps: () => ({
                loading: this.loading,
                fab: true,
                small: true
              })
            },
            {
              icon: "fal fa-times",
              action: this.cancel,
              vuetifyProps: () => ({
                loading: this.loading,
                fab: true,
                small: true
              })
            }
          ]
        }
      });
    }
  }
}
